<template>
  <div v-show="false" class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">VISITOR</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1"> Welcome to VISITOR! 👋 </b-card-title>
          <!-- <b-card-text class="mb-2"> Please sign-in to your account and start the adventure </b-card-text> -->

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- username -->
              <b-form-group label="Username" label-for="login-user">
                <validation-provider #default="{ errors }" name="user" rules="required">
                  <b-form-input id="login-user" v-model="username" :state="errors.length > 0 ? false : null" name="login-user" placeholder="Username" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <!-- <b-link :to="{ name: 'auth-forgot-password-v2' }">
                    <small>Forgot Password?</small>
                  </b-link> -->
                </div>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null" class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block @click="validationForm"> Sign in </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'page-auth-register-v2' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">or</div>
          </div>

          
          
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>  -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
  /* eslint-disable global-require */
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import VuexyLogo from '@core/layouts/components/Logo.vue';
  import { required } from '@validations';
  import { togglePasswordVisibility } from '@core/mixins/ui/forms';

  import store from '@/store/index';
  // LIFF initialization
  import liff from '@line/liff';
  import axios from 'axios';
  import API from '@/connect/config';

  export default {
    components: {
      VuexyLogo,
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        sideImg: require('@/assets/images/pages/login-v2.svg'),
        // validation rulesimport store from '@/store/index'
        required,
        username: 'admin@wac34',
        password: 'Password@1',
        page: 0,
        test: true,
      };
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
      },
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
          return this.sideImg;
        }
        return this.sideImg;
      },
    },
    created() {
      console.log(process.env.NODE_ENV);
      //  window.open('https://visitors.wacsvdev.com/login_line.html?liffId=2004209722-2xrJNErl&id=1');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('profile_visitor');
      localStorage.removeItem('info_line');
      localStorage.removeItem('company_jwt_vis');
      localStorage.removeItem('company_vis');

      this.lineLogin();
      //this.$store.dispatch("getAuthorization");
    },
    methods: {
      async lineLogin() {
        if (process.env.NODE_ENV == 'development' && this.test === true) {
          console.log('-----test-----');
          const liffId = '2004209722-2xrJNErl';
          const urlParams_URL = new URLSearchParams(window.location.search);
          let liffIds = urlParams_URL.get('liffId');
          console.log(liffIds);
          // location.href = `/login_line?liffId=${liffId}`;
          console.log(liffId);
          const line_userId = 'U8fe8acd71018d5f1bb415a9530abd9fb';
          const line_name = '._diamond';
          this.page = 1;
          liff.init({ liffId: liffId }).then(async () => {
            console.log('LIFF initialized');
            const info_line = {
              liffId: liffId,
              line_userId: line_userId,
              line_name: line_name,
            };
            localStorage.setItem('info_line', JSON.stringify(info_line));
            this.getLogin(liffId, info_line.line_userId);
          });
          //console.log(profile);
        } else {
          const urlParams_URL = new URLSearchParams(window.location.search);
          let liffId = urlParams_URL.get('liffId');
          console.log(liffId);
          //let liffId = '2004209722-2xrJNErl'; // ;
          //page chang
          this.page = urlParams_URL.get('id'); //  1 , 2, 3   urlParams_URL.get('id');
          let line_userId = '';
          let line_name = '';
          if (liffId != undefined) {
            await liff.init({ liffId: liffId });
            if (!liff.isLoggedIn()) {
              liff.login();
              return false;
            }
            const profile = await liff.getProfile();
            line_name = profile.displayName;
            line_userId = profile.userId;
            const info_line = {
              liffId: liffId,
              line_userId: line_userId,
              line_name: line_name,
            };
            console.log(info_line);
            localStorage.setItem('info_line', JSON.stringify(info_line));
            this.getLogin(liffId, info_line.line_userId);
          }
        }

        // await liff
        //   .init({ liffId: liffId })
        //   .then(async () => {
        //     console.log('LIFF initialized');

        //     liff.login();
        //     const profile = await liff.getProfile();
        //     console.log(profile);
        //     line_userId = profile.line_userId;
        //     line_name = profile.line_name;
        //     const info_line = {
        //       liffId: liffId,
        //       line_userId: line_userId,
        //       line_name: line_name,
        //     };
        //     localStorage.setItem('info_line', JSON.stringify(info_line));
        //     //this.getLogin(liffId,line_userId );
        //     this.getLogin(liffId, info_line.line_userId);
        //   })
        //   .catch((err) => {
        //     console.error('LIFF initialization failed', err);
        //   });
      },
      async getLogin(liffId, line_userId) {
        await this.$store
          .dispatch('postData', {
            _url: 'login',
            _data: { liffId: liffId, lineId: line_userId },
          })
          .then(async (res) => {
            localStorage.setItem('refresh_token', JSON.stringify(res.message.refresh_token));
            const jwt = await this.$store.dispatch('getJwtDecode', res.message.refresh_token);
            localStorage.setItem('company_jwt_vis', JSON.stringify(jwt));
            let res_user = await axios.post(`${API}user`, { userId: jwt.userId });
            // console.log(res_user.data.message);

            if (res.message.err == 'lineId not found') {
              console.log('-----------------------lineId not found------------------------------------------');
              res_user.data.message.data.map((el) => {
                if (el.rule == 'Master Admin') {
                  localStorage.setItem('profile_visitor', JSON.stringify(el));
                  this.$router.push({ name: 'user_regis' });
                }
              });
            } else {
              console.log('------------------------Login true-----------------------------------------');
              axios.get(`${API}logo/${jwt.userId}`).then(async (ellogo) => {
                console.log(ellogo);
                if (ellogo.data.message.length != 0) {
                  localStorage.setItem('company_vis', JSON.stringify(ellogo.data.message.data[0]));
                } else {
                  localStorage.setItem('company_vis', JSON.stringify({}));
                }
               

                res_user.data.message.data.map((el) => {
                  if (el.rule == 'member' && el.lineId == line_userId) {
                    localStorage.setItem('profile_visitor', JSON.stringify(el));
                    if (this.page == 1) {
                      this.$router.push({ name: 'home' });
                    } else if (this.page == 2) {
                      this.$router.push({ name: 'addMeet' });
                    } else if (this.page == 3) {
                      this.$router.push({ name: 'qrcode' });

                      // this.$router.push({ name: 'addMeet' });
                    }
                  }
                });
              });
              this.$store.commit('setToastMessage', {
                message: `Login Success`,
                title: 'Welcome',
                variant: 'success',
              });
            }

            // localStorage.setItem('refresh_token', JSON.stringify(res.message.refresh_token));
            // const jwt = await this.$store.dispatch('getJwtDecode', res.message.refresh_token);
            // localStorage.setItem('profile_visitor', JSON.stringify({ username: user, ...jwt }));
            // this.$router.push({ name: 'home' });
            // this.$store.commit('setToastMessage', {
            //   message: 'Login Success',
            //   title: 'Login',
            //   variant: 'success',
            // });
          })
          .catch((error) => {
            console.log(error.response);
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: 'Notification',
            //     icon: 'EditIcon',
            //     text: 'Login error',
            //     variant: 'danger',
            //   },
            // });
            this.$store.commit('setToastMessage', {
              message: `${error}`,
              title: 'Login',
              variant: 'danger',
            });
          });
      },
      validationForm() {
        this.$refs.loginValidation.validate().then((success) => {
          if (success) {
            this.getLogin(this.username, this.password);
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Form Submitted",
            //     icon: "EditIcon",
            //     variant: "success",
            //   },
            // });
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
